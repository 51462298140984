import React from "react"
import "./footer.css"

const Footer = () => (
    <>
        <div className="footer">
            Copyright © 2019 nut sathaporn. All rights reserved
        </div>

    </>
)

export default Footer
