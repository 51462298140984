import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

const Header = ({ siteTitle }) => (
  <>
    <div className="navbar">
      <div className="container">
        <div className="navbar-title">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >{siteTitle}</Link></div>

        <div className="navbar-menu">
          <div className="menu-item">  
            {/* <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >ทดสอบ</Link>  */}
          </div>
        </div>
      </div>
    </div >
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
